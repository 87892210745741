/**
 * @file    CreditsEarned.tsx - Exports a card component that displays the
 *          total amount of DCC earned.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

import type BigNumber from 'bignumber.js';
import Image from 'next/image';

import CREDIT from '@/assets/img/dcp-credit-symbol.svg';
import { Card } from '@/components/ui/card';
import { Title } from '@/components/ui/title';
import { Balance } from '@/features/account';

type WorkerStatsProps = {
  totalCreditsEarned: BigNumber;
};

/**
 * Renders a card that displays the total amount of DCCs earned.
 *
 * @param props - Component properties.
 * @param props.totalCreditsEarned - The amount of DCCs earned.
 * @returns The rendered component.
 */
function CreditsEarned({ totalCreditsEarned }: WorkerStatsProps) {
  return (
    <>
      <Card className="flex items-start justify-start px-[30px] pt-[15px] pb-[0px] xl:flex-col xl:p-[30px] xl:pt-[30px] ">
        <Title className="dcp-text-col mb-[0] w-[100%] font-bold text-base xl:mb-[10px] xl:text-xl">
          Credits Earned
        </Title>
        {/* TODO: fix absolute to relative. */}
        <div className="flex w-[100%] flex-wrap items-center justify-end gap-[6px] font-[400] font-source-code text-[20px] xl:absolute xl:top-0 xl:right-0 xl:bottom-0 xl:left-[-30px] xl:flex xl:items-center">
          <Balance balance={totalCreditsEarned} />
          <Image
            src={CREDIT}
            alt="credit"
            height={18}
            className="relative top-[1px]"
          />
        </div>
      </Card>
    </>
  );
}

export { CreditsEarned };
