/**
 * @file    TotalComputingTime.tsx - Exports card component that displays total
 *          computing time.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

import { Card } from '@/components/ui/card';
import { Title } from '@/components/ui/title';
type WorkerStatsProps = {
  /**
   * In milliseconds.
   */
  totalComputingTime: number;
};

/**
 * Renders a card that formats and displays the total computing time.
 *
 * @param props - Component properties.
 * @param props.totalComputingTime - The time to format and display, in milliseconds.
 * @returns The rendered component.
 */
function TotalComputingTime({ totalComputingTime }: WorkerStatsProps) {
  const computeTime = formatTimeInterval(totalComputingTime);

  return (
    <>
      <Card className="flex items-center justify-between gap-[20px] px-[30px] pt-[0px] pb-[20px] xl:block xl:p-[30px] xl:pb-[30px]">
        <Title className="dcp-text-col mb-0 font-bold text-base xl:mb-[10px] xl:text-xl">
          Computing Time
        </Title>
        <div className="relative flex flex-col items-end gap-[0px] xl:gap-[6px]">
          <div className="absolute top-[110%] left-[45%] flex w-[100%] translate-x-[-50%] translate-y-[-50%] flex-nowrap justify-end gap-[10px] font-[400] text-[9px] opacity-[50%] xl:relative xl:top-0 xl:left-0 xl:translate-x-0 xl:translate-y-0 xl:flex-wrap xl:text-[11px]">
            <p>DD</p>
            <div>:</div>
            <p>HH</p>
            <div>:</div>
            <p>MM</p>
          </div>
          <p className="relative font-source-code text-[20px]">
            {`${computeTime.days}:${computeTime.hours}:${computeTime.minutes}`}
          </p>
        </div>
      </Card>
    </>
  );
}

type ComputeTime = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

/**
 * Transforms an elapsed amount of time and converts it into an object with the
 * time in different units.
 *
 * @param ms - Elapsed time, measured in milliseconds.
 * @returns An object describing the elapsed time in different units.
 */
function formatTimeInterval(timeIntervalMs: number): ComputeTime {
  const timeInterval: ComputeTime = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  };

  let ms = Math.floor(timeIntervalMs);

  const MS_IN_DAY = 86400000;
  const MS_IN_HOUR = 3600000;
  const MS_IN_MIN = 60000;
  const MS_IN_SEC = 1000;

  if (ms >= MS_IN_DAY) {
    const stringDays = Math.floor(ms / MS_IN_DAY).toFixed(0);
    timeInterval.days = stringDays.length > 1 ? stringDays : `0${stringDays}`;
    ms = ms % MS_IN_DAY;
  }
  const stringHours = (Math.floor(ms / MS_IN_HOUR) % 24).toString();
  const stringMinutes = (Math.floor(ms / MS_IN_MIN) % 60).toString();
  const stringSeconds = (Math.floor(ms / MS_IN_SEC) % 60).toFixed(1);

  timeInterval.hours = stringHours.length > 1 ? stringHours : `0${stringHours}`;
  timeInterval.minutes =
    stringMinutes.length > 1 ? stringMinutes : `0${stringMinutes}`;
  timeInterval.seconds = stringSeconds;

  return timeInterval;
}

export { TotalComputingTime };
