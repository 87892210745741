/**
 * @file    SandboxAnimation.tsx - Exports a component that renders an animation
 *          based on either the sandbox is working.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov 2023
 */

import { useEffect, useState } from 'react';

import Spinner from '@/components/ui/spinner';
import { Title } from '@/components/ui/title';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { debugLog } from '@/lib/debug';
import { type ProgressEvent, SandboxInfo } from '@/types';

import { ReadySpinner } from './ReadySpinner';

const debugSandboxLog = debugLog.extend('sandbox');

interface SandboxAnimationProps {
  sandbox: any;
}

/**
 * Renders an animation based on if a sandbox is working.
 *
 * @param props - Component properties.
 * @param props.sandbox - The sandbox.
 * @returns The rendered component.
 */
function SandboxAnimation({ sandbox }: SandboxAnimationProps): JSX.Element {
  const [progress, setProgress] = useState<ProgressEvent>(undefined);
  const [sandboxInfo, setSandboxInfo] = useState(SandboxInfo.Ready);

  const [jobDetails, setJobDetails] = useState(
    sandbox.public || {
      description: '',
      link: '',
      name: '',
    },
  );

  useEffect(() => {
    // When the worker has decided that a specific sandbox will be associated with a specific job.
    const handleSandboxJob = () => {
      debugSandboxLog('Sandbox initializing with job');
      setSandboxInfo(SandboxInfo.Ready);
    };

    const handleSandboxSlice = () => {
      setJobDetails({
        name: sandbox.public.name,
        description: sandbox.public.description,
        link: sandbox.public.link,
      });
    };

    const clearSandboxDetails = () => {
      setJobDetails((prev: any) => {
        if (sandboxInfo === SandboxInfo.Ready)
          return {
            name: '',
            description: '',
            link: '',
          };
        return prev;
      });
    };

    // The ready event is fired every time the sandbox is ready to accept new work.
    const handleSandboxReady = () => {
      debugSandboxLog('Ready');
      setProgress(undefined);
      clearSandboxDetails();
      setSandboxInfo(SandboxInfo.Ready);
    };

    // Determines the progress of the slice and updates the progress status
    const handleSandboxProgress = (progressEvent: ProgressEvent) => {
      if (progressEvent === progress) return;

      setSandboxInfo(SandboxInfo.Progress);
      setProgress(progressEvent);
    };

    // The payment event is fired after a result from work done in this sandbox
    // is sent to the result-submitter for consideration.
    const handleSandboxPayment = (event: any) => {
      debugSandboxLog('Paid:', event);
      setSandboxInfo(SandboxInfo.Paid);
      setProgress(100);
    };

    const onSliceEnd = () => {
      // Add a delay on the ending animation
      setTimeout(() => {
        handleSandboxEnd();
      }, 1500);
    };

    // Once the worker has destroyed all internal resources and references
    // relating to the sandbox.
    const handleSandboxEnd = () => {
      debugSandboxLog('Sandbox ended');
      setSandboxInfo(SandboxInfo.Completed); //This allows the ending animation to complete before jumping to the next slice of work

      //Delay changing the state immediately to ready once ended
      setTimeout(() => {
        handleSandboxReady();
      }, 2000);
    };

    const handleSandboxError = (event: any) => {
      debugSandboxLog('Sandbox error:', event);
      setSandboxInfo(SandboxInfo.Error);
    };

    //Ties the declared event handlers to their corresponding functionality
    sandbox.sandboxHandle.on('ready', handleSandboxReady);
    sandbox.sandboxHandle.on('job', handleSandboxJob);
    sandbox.sandboxHandle.on('slice', handleSandboxSlice);
    sandbox.sandboxHandle.on('progress', handleSandboxProgress);
    sandbox.sandboxHandle.on('payment', handleSandboxPayment);
    sandbox.sandboxHandle.on('sliceEnd', onSliceEnd);
    sandbox.sandboxHandle.on('sliceError', handleSandboxError);
    // Clean up function
    return () => {
      sandbox.sandboxHandle.off('ready', handleSandboxReady);
      sandbox.sandboxHandle.off('job', handleSandboxJob);
      sandbox.sandboxHandle.off('slice', handleSandboxSlice);
      sandbox.sandboxHandle.off('progress', handleSandboxProgress);
      sandbox.sandboxHandle.off('payment', handleSandboxPayment);
      sandbox.sandboxHandle.off('sliceEnd', onSliceEnd);
      sandbox.sandboxHandle.off('sliceError', handleSandboxError);
    };
  }, [progress, sandbox.public, sandbox.sandboxHandle, sandboxInfo]);

  return (
    <>
      <div className="flex max-w-[100%] flex-col items-center justify-end">
        {/* Loading Circle */}
        {sandboxInfo === SandboxInfo.Ready ? (
          <ReadySpinner />
        ) : (
          <Spinner progress={progress} />
        )}

        {/* Sandbox Details */}
        <div className="flex w-[100%] max-w-[80px] flex-col items-center justify-center overflow-hidden md:max-w-[auto]">
          {/* Sandbox Status */}
          <Title className="mb-[0] block text-center font-bold text-[10px]">
            {sandboxInfo}
          </Title>

          {/* Sandbox Name */}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="w-[100%]">
                {/* Job Name */}
                <Title className="mb-[0] block w-[100%] cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap text-center text-[12px]">
                  {!(sandboxInfo === SandboxInfo.Ready) && jobDetails.name}
                </Title>
              </TooltipTrigger>

              {/* Job Name Tool Tip */}
              {!(sandboxInfo === SandboxInfo.Ready) && (
                <TooltipContent>
                  <p>{jobDetails.name}</p>
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </>
  );
}

export { SandboxAnimation };
