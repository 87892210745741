/**
 * @file    spinner.tsx - spinning animation for icon
 *
 * @author  Kevin Yu <kevin@distributive.network>
 * @date    Feb 2024
 */

import Image from 'next/image';

import { cn } from '@/lib/utils';

import Loader from '../../assets/img/sandbox-loader.png';
/**
 * Sandbox spinner component
 *
 * @param props - component props
 * @param props.progress - sandbox progress
 * @returns The sandbox animation
 */
function Spinner({ progress }: any) {
  const radius = 27; // Same as your SVG circle's radius
  const circumference = 2 * Math.PI * radius;
  const curProgress = progress ?? 0;
  // Calculate the stroke dash offset based on progress
  const strokeDashoffset = ((100 - curProgress) / 100) * circumference;

  return (
    <div className="relative">
      <div className="relative rotate-[-90deg]">
        <div
          className={cn(
            'absolute top-[50%] left-[50%] h-[52px] w-[52px] translate-x-[-50%] translate-y-[-50%] rounded-[50%] opacity-0 transition-all duration-1000',
            { 'bg-[#1aa473] opacity-[1]': curProgress === 100 },
          )}
        />

        <svg width={80} height={80} className="z-20">
          <title>Sandbox progress ({curProgress}%)</title>
          <circle
            cx="40"
            cy="40"
            r={radius}
            fill={'none'}
            stroke="#1aa473"
            strokeWidth="3.5"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            style={{ transition: ' 0.1s ease-out' }} // Smooth transition for the drawing effect
          />
        </svg>
      </div>

      <div
        className={cn(
          'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]',
        )}
      >
        {/* Spinner */}
        <div
          className={cn('relative h-[45px] w-[45px] transition-all', {
            'opacity-0': progress === 100,
          })}
        >
          <Image className="animate-spin" src={Loader} fill alt="loader" />
        </div>

        {/* Percentage */}
        {!(progress == null) && (
          <div className="absolute top-[50%] left-[50%] flex translate-x-[-50%] translate-y-[-50%] items-center justify-center text-[12px]">
            <span>{Math.floor(Number.parseFloat(curProgress))}%</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Spinner;
