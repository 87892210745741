/**
 * @file    types.ts - Defines types used by `dcp-client` in the web that we
 *          haven't provided through DefinitelyTyped.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Mar. 2023
 */

import type { Keystore } from 'dcp/wallet';

import type { Address } from '@/features/account';

declare global {
  interface Window {
    dcpConfig: any;
    gtag: (...args: any[]) => void;
    dcpWorker?: Worker;
  }
}

declare class EventTarget<T> {
  on<E extends keyof T>(event: E, eventListener: T[E]): this;
  off<E extends keyof T>(event: E, eventListener: T[E]): this;
}

export declare interface Receipt {
  payment: string;
}

declare interface WorkerEvents {
  start: EventHandler<undefined>;
  fetchStart: EventHandler<undefined>;
  error: EventHandler<Error>;
  fetchEnd: EventHandler<WorkerErrorEvent | number>;
  stop: EventHandler<undefined>;
  payment: EventHandler<Receipt>;
}

export declare class Worker extends EventTarget<WorkerEvents> {
  constructor(identity: Keystore, options: WorkerOptions);
  start: () => Promise<void>;

  stop: (shouldstopImmediately: boolean) => Promise<void>;
}

export interface JobHandle {
  address: Address;
  name: string;
  description: string;
  link: string;
}

declare type EventHandler<E> = (event: E) => void;

export declare interface SliceErrorEvent extends ErrorEvent {}
export declare interface WorkerErrorEvent extends ErrorEvent {}

export type ProgressEvent = number | undefined;
export enum SandboxInfo {
  Ready = 'Ready',
  Progress = 'Working',
  Paid = 'Paid',
  Completed = 'Completed',
  Error = 'Error',
}
declare interface SandboxEvents {
  /**
   * The ready event is fired every time the sandbox is ready to accept new
   * work.
   */
  ready: EventHandler<undefined>;
  /**
   * The job event is fired when the worker has decided that a specific sandbox
   * will be associated with a specific job. It receives as its sole argument an
   * object which describes the job that is also an EventEmitter. This is the
   * same object, or same kind of object, which is emitted by the `Worker<job>`
   * event.
   */
  job: EventHandler<JobHandle>;
  /**
   * The slice event is fired as soon as the worker assigns a slice to the
   * sandbox.
   */
  slice: EventHandler<number>;
  /**
   * The progress event is fired to indicate progress throughout a job; this is
   * triggered by the `progress()` call in the work function, however,
   * quickly-repeating calls to `progress()` may be composited into a single
   * event.
   *
   * If the event handler's argument is undefined, this indicates a work
   * function whose progress is indeterminate and potentially unknowable; if the
   * work function supplies a numeric argument (expected to be between 0 and
   * 100), this argument will passed instead.
   *
   * If the work function supplies an argument which is less than 0 or greater
   * than 100, the event handler will receive undefined.
   */
  progress: EventHandler<ProgressEvent>;
  /**
   * The metric event fires as soon as the worker has measured the execution of
   * the work function for one slice. This is the same as the
   * `JobHandle<metrics>` event.
   */
  metrics: (slice: number, measurement: Record<string, any>) => void;
  /**
   * The sliceEnd event fires immediately after the metrics event.
   */
  sliceEnd: EventHandler<number>;
  /**
   * The payment event is fired after a result from work done in this sandbox is
   * sent to the result-submitter for consideration. The event handler receives
   * the same arguments as the `Worker<payment>` event.
   */
  payment: (payment: string, paymentAccount: string, slice: number) => void;
  /**
   * The end event once the worker has destroyed all internal resources and
   * references relating to the sandbox.
   */
  end: EventHandler<undefined>;
}

export declare class Sandbox {
  id: string;

  isWorking: boolean;

  public: Omit<JobHandle, 'address'>;

  sliceStartTime: number;

  progress: number;

  sandboxHandle: EventTarget<SandboxEvents>;
}

export declare class SandboxHandle extends EventTarget<SandboxEvents> {
  id: string;

  public: Omit<JobHandle, 'address'>;
}

export interface WorkerOptionsModalFormElements
  extends HTMLFormControlsCollection {
  maxWorkingSandboxesInput: HTMLInputElement;
  schedulerURLSelect: HTMLSelectElement;
  bankAccountInput: HTMLInputElement;
  setShouldNotStopWorkingImmediatelyCheckbox: HTMLInputElement;
}
