/**
 * @file    JoinKeyForm.tsx - A form for accepting join keys of a compute group.
 *
 * @author  Bryan Hoang <bryan@distributve.network>
 * @date    Nov. 2023
 */

import { valibotResolver } from '@hookform/resolvers/valibot';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type InferOutput, object, string } from 'valibot';

import { Button } from '@/components/ui/button';
import { Form, FormField, FormItem, FormLabel } from '@/components/ui/form';

export const joinKeySchema = object({
  joinKey: string(),
});

interface ComputeGroupInputProps {
  joinKey: string;
  onSubmit: SubmitHandler<InferOutput<typeof joinKeySchema>>;
}

/**
 * Renders a form to allow inputting a join key.
 *
 * @param props - Component properties.
 * @param props.joinKey - The currently selected join key.
 * @param props.onSubmit - submit handler function for changing compute groups
 * @returns The rendered component.
 */
export function JoinKeyForm({
  joinKey,
  onSubmit,
}: ComputeGroupInputProps): JSX.Element {
  const form = useForm<InferOutput<typeof joinKeySchema>>({
    resolver: valibotResolver(joinKeySchema),
    defaultValues: {
      joinKey,
    },
  });

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="joinKey"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between xl:flex-col xl:items-start xl:justify-start">
                <FormLabel className="dcp-text-col flex w-[100%] flex-col font-bold text-base">
                  Compute Group
                </FormLabel>
                <div className="flex w-[100%] items-center justify-end gap-[7px]">
                  <FormLabel className="relative pl-[19px] font-[600] text-[18px] xl:w-[100%] xl:bg-[#ffffff33] xl:py-[8px]">
                    {field.value}
                  </FormLabel>
                  <button
                    type="button"
                    className="block font-[600] text-[#6CCA98] text-[24px] xl:hidden"
                  >
                    +
                  </button>
                </div>
              </FormItem>
            )}
          />
          <div className="mt-[1.6rem] hidden w-[100%] justify-end xl:flex">
            <Button variant="secondary" type="submit">
              CHANGE
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
