/**
 * @file    JoinCredentialDialog.tsx - Exports a component that renders a dialog
 *          containing a form to accept a join credential for a compute group.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    June 2021
 */

import { valibotResolver } from '@hookform/resolvers/valibot';
import { usePathname } from 'next/navigation';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type InferOutput, object, string } from 'valibot';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { DEFAULT_JOIN_KEY } from '@/config';

const joinCredentialSchema = object({
  joinCredential: string(),
  joinKey: string(),
});

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  onSubmit: SubmitHandler<InferOutput<typeof joinCredentialSchema>>;
}

/**
 * Renders a modal that allows for joining a compute group.
 *
 * @param props - The component's properties.
 * @param props.show - Whether to show the modal or not.
 * @param props.setShow - A setter for the show state.
 * @param props.onSubmit - An event handler for the submit event.
 * @returns The rendered component.
 */
export function JoinCredentialDialog({
  show,
  setShow,
  onSubmit,
}: Props): JSX.Element {
  const pathName = usePathname();

  const form = useForm<InferOutput<typeof joinCredentialSchema>>({
    resolver: valibotResolver(joinCredentialSchema),
    defaultValues: {
      joinKey: pathName.slice(1),
      joinCredential: '',
    },
  });

  const onOpenChange = () => {
    if (show) {
      form.reset();
      setShow(false);
    }
  };

  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className="text-left">Join Compute Group</DialogTitle>
        </DialogHeader>
        <hr className="border-white" />
        <Form {...form}>
          <form
            id="joinCredential"
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-3"
          >
            <FormField
              control={form.control}
              name="joinKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Join Key:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      spellCheck="false"
                      placeholder={DEFAULT_JOIN_KEY}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="joinCredential"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Join Secret or Join Hash:</FormLabel>
                  <FormControl>
                    <Input type="password" spellCheck="false" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogFooter className="pt-[24px]">
          <Button type="submit" form="joinCredential">
            JOIN
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
