/**
 * @file    DepositAccountForm.tsx - Exports a form component for entering
 *          a bank account to deposit credits into.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Sept. 2023
 */
import { useState } from 'react';
import { type InferOutput, object, string } from 'valibot';

import { Title } from '@/components/ui/title';
import { AccountModal } from '@/features/account/components/AccountModal';
import { debugLog } from '@/lib/debug';

interface BankAccountInputProps {
  worker: any;
  defaultDepositAccount: string;
  depositAccount: string | Address | Error;
  setDepositAccount: (address: string) => void;
}

const depositAccountSchema = object({
  depositAccount: string(),
});

/**
 * Renders a form component for entering a bank account.
 *
 * The form's input value must be synced the worker's payment bank account as a
 * side effect.
 *
 * @param props - Component properties.
 * @param props.worker - The instance of the worker, whose payment account we need to stay in sync with.
 * @param props.defaultDepositAccount - The default value used for the input.
 * @param props.depositAccount - The default value used for the input.
 * @param props.setDepositAccount - The setter for the bank account state.
 * @returns The rendered component.
 */
export function DepositAccountForm({
  worker = { workerOptions: { paymentAddress: '' } },
  defaultDepositAccount,
  depositAccount,
  setDepositAccount,
}: BankAccountInputProps): JSX.Element {
  const [modal, setModal] = useState(false);

  function onSubmit(values: InferOutput<typeof depositAccountSchema>) {
    debugLog('DepositAccountForm values:', values);
    if (values.depositAccount === '') {
      setDepositAccount(defaultDepositAccount);
    } else {
      setDepositAccount(values.depositAccount);
    }
    setModal(false);
  }

  return (
    <div className="flex flex-row items-center justify-between xl:flex-col xl:items-start xl:justify-start">
      <Title className="dcp-text-col mb-[0] w-[100%] font-bold text-base xl:mb-[10px] xl:text-xl">
        Deposit Account
      </Title>
      <div className="relative mt-2 hidden w-[100%] xl:block">
        <button
          type="button"
          className="focus-visible:ri ng-offset-2 flex w-[100%] overflow-hidden text-ellipsis whitespace-nowrap bg-[#ffffff33] px-[20px] py-[8px] font-bold font-source-code text-[18px] text-lg text-white ring-offset-background file:border-0 file:bg-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {
            setModal(true);
          }}
        >
          <span className="overflow-hidden text-ellipsis">
            {depositAccount.toString()}
          </span>
        </button>
      </div>
      <div className="flex w-[100%] items-center justify-end gap-[7px]">
        <label className="block w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap text-right font-source-code text-[18px] xl:hidden xl:w-[100%]">
          {depositAccount.toString()}
        </label>
        <button
          type="button"
          className="block font-[600] text-[#6CCA98] text-[24px] xl:hidden"
          onClick={() => {
            setModal(true);
          }}
        >
          +
        </button>
      </div>
      <AccountModal
        worker={worker}
        depositAccount={depositAccount}
        defaultDepositAccount={defaultDepositAccount}
        show={modal}
        setShow={setModal}
        onSubmit={onSubmit}
      />
    </div>
  );
}
