/**
 * @file    CPUCoresForm.tsx - TODO:
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @author  David Courtis <david@distributive.network>
 * @date    Nov. 2023
 */

import type Worker from 'dcp/worker';

import { ComboBox } from '@/components/ui/comboBox';
import { Title } from '@/components/ui/title';

interface BankAccountInputProps {
  worker: Worker;
}

/**
 * CPU Cores Block
 *
 * @param props - component props
 * @param props.worker - the worker instance
 *
 * @returns the rendered component
 */
function CPUCoresForm({ worker }: BankAccountInputProps): JSX.Element {
  return (
    <>
      <div className="flex flex-row items-center justify-between xl:flex-col xl:items-start xl:justify-start">
        <Title className="dcp-text-col mb-[0] w-[100%] font-bold text-base xl:mb-[10px] xl:text-xl">
          CPU Cores
        </Title>
        <ComboBox worker={worker} />
      </div>
    </>
  );
}

export { CPUCoresForm };
