/**
 * @file    useAddress.ts - Exports a React Hook which deals with common logic
 *          used when dealing with Address types from `dcp-client`.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Aug. 2021, Sept. 2023
 */

import { useCallback, useEffect } from 'react';
import {
  type QueryParamConfig,
  decodeString,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { DEFAULT_BANK_ACCOUNT } from '@/config';

import type { Address as AddressInstance, BankAccount } from '../types';

let Address: typeof AddressInstance;

const BankAddressParam: QueryParamConfig<
  string | Address,
  /**
   * If the address is invalid, decode it as the error thrown from trying to
   * coerce it using the `Address` constructor.
   */
  BankAccount | undefined | null
> = {
  encode(value) {
    const encodedValue = String(new Address(value));
    return encodedValue;
  },
  decode(value) {
    const decodedValue = decodeString(value);
    if (typeof decodedValue === 'undefined' || decodedValue === null) {
      return decodedValue;
    }

    try {
      return new Address(decodedValue);
    } catch (error) {
      return error as Error;
    }
  },
};

/**
 * Accepts a string representing a default address and returns the corresponding
 * state and its setter.
 *
 * Reads and sets the `bankAccount` parameter in the page's search string.
 *
 * @returns A state representing the address and its setter.
 */
export function useAddress(): [BankAccount, (address: string) => void] {
  const [{ bankAccount }, setQuery] = useQueryParams({
    bankAccount: withDefault(BankAddressParam, DEFAULT_BANK_ACCOUNT),
  });

  // Capture the constructor from the API once the component mounts.
  useEffect(() => {
    Address = window.dcp.wallet.Address;
  }, []);

  /**
   * Sets the value of the address returned from the hook
   *
   * @param newAddress - The new address value to use.
   */
  const setAddress = useCallback(
    (newAddress: string) => {
      try {
        setQuery({ bankAccount: newAddress });
      } catch (error) {
        setQuery({ bankAccount: DEFAULT_BANK_ACCOUNT });
      }
    },
    [setQuery],
  );

  return [bankAccount, setAddress];
}
