/**
 * @file    JoinCredentialDialog.tsx - Exports a component that renders a dialog
 *          containing a form to accept a join credential for a compute group.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    June 2021
 */

import { valibotResolver } from '@hookform/resolvers/valibot';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type InferOutput, object, string } from 'valibot';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Title } from '@/components/ui/title';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

const coreSchema = object({
  selectCores: string(),
});

interface Props {
  show: boolean;
  cores: Array<number>;
  targetCores: number;
  setShow: (show: boolean) => void;
  onSubmit: SubmitHandler<InferOutput<typeof coreSchema>>;
}

/**
 * Renders a modal that allows for joining a compute group.
 *
 * @param props - The component's properties.
 * @param props.show - Whether to show the modal or not.
 * @param props.cores - The list of cores to choose from.
 * @param props.targetCores - The number of cores to join.
 * @param props.setShow - A setter for the show state.
 * @param props.onSubmit - An event handler for the submit event.
 * @returns The rendered component.
 */
export function ComboBoxDialog({
  show,
  cores,
  targetCores,
  setShow,
  onSubmit,
}: Props): JSX.Element {
  const isWebKit = navigator.userAgent.indexOf('iPhone') !== -1;
  const form = useForm<InferOutput<typeof coreSchema>>({
    resolver: valibotResolver(coreSchema),
    defaultValues: {
      selectCores: targetCores ? targetCores.toString() : '1',
    },
  });

  // Detects when user clicks off the modal or the 'x'
  const onOpenChange = () => {
    if (show) {
      form.reset();
      setShow(false);
    }
  };

  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className="text-left">Change CPU Cores</DialogTitle>
        </DialogHeader>
        <hr className="border-white" />
        <Form {...form}>
          <form
            id="selectCores"
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="selectCores"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Select Cores:</FormLabel>
                  <FormControl>
                    {/* Sandbox Name */}
                    {isWebKit ? (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger className="w-[100%]">
                            <Input
                              type="number"
                              placeholder={targetCores.toString()}
                              {...field}
                              min="1"
                              max={cores.length.toString()}
                              disabled={isWebKit}
                            />
                          </TooltipTrigger>
                          <TooltipContent>
                            <Title className="mb-[0] block w-[100%] cursor-pointer whitespace-nowrap text-center text-[12px]">
                              {'Currently unsupported on IOS devices'}
                            </Title>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : (
                      <Input
                        type="number"
                        placeholder={targetCores.toString()}
                        {...field}
                        min="1"
                        max={cores.length.toString()}
                        disabled={isWebKit}
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogFooter>
          <Button type="submit" form="selectCores">
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
