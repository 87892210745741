/**
 * @file    ReadySpinner.tsx - An animated spinner indicating sandboxes that are
 *          ready for work.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Feb. 2024
 */

import Image from 'next/image';

import matrix1 from '@/assets/img/matrix-1.png';
import matrix2 from '@/assets/img/matrix-2.png';
import matrix3 from '@/assets/img/matrix-3.png';

/**
 * Renders an animated spinner.
 *
 * @returns The rendered component.
 */
export function ReadySpinner(): JSX.Element {
  return (
    <>
      <div className="relative h-[80px] w-[80px]">
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
          {/* Matrix 1 */}
          <div className="relative h-[50px] w-[50px]">
            <Image
              src={matrix3}
              alt="Matrix"
              fill
              className="-[-80deg] animate-matrix-spin-1"
            />
          </div>
        </div>
        {/* Matrix 2 */}
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="relative h-[38px] w-[38px]">
            <Image
              src={matrix1}
              alt="Matrix"
              fill
              className="-[30deg] animate-matrix-spin-2"
            />
          </div>
        </div>
        {/* Matrix 3 */}
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="relative h-[29px] w-[29px]">
            <Image
              src={matrix2}
              alt="Matrix"
              fill
              className="-[-50deg] animate-matrix-spin-3"
            />
          </div>
        </div>
        {/* Ellipses */}
        <span className="ellipses !text-[25px] absolute top-[50%] left-[50%] flex h-[10px] translate-x-[-50%] translate-y-[-50%] items-center justify-center text-center" />
      </div>
    </>
  );
}
