/**
 * @file    DepositAccountForm.tsx - Exports a form component for entering
 *          a bank account to deposit credits into.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Sept. 2023
 */

import { valibotResolver } from '@hookform/resolvers/valibot';
import { useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type InferOutput, object, string } from 'valibot';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

interface BankAccountInputProps {
  worker: any;
  depositAccount: string | Address | Error;
  defaultDepositAccount: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onSubmit: SubmitHandler<InferOutput<typeof depositAccountSchema>>;
}

const depositAccountSchema = object({
  depositAccount: string(),
});

/**
 * Renders a form component for entering a bank account.
 *
 * The form's input value must be synced the workerDepositAccountForm's payment bank account as a
 * side effect.
 *
 * @param props - Component properties.
 * @param props.worker - The instance of the worker, whose payment account we need to stay in sync with.
 * @param props.depositAccount - The current value used for the input.
 * @param props.defaultDepositAccount - The default value used for the input.
 * @param props.show - Whether to show the modal or not.
 * @param props.setShow - The setter for the modal state.
 * @param props.onSubmit - An event handler for the submit event.
 * @returns The rendered component.
 */
export function AccountModal({
  worker = { workerOptions: { paymentAddress: '' } },
  depositAccount,
  defaultDepositAccount,
  show,
  setShow,
  onSubmit,
}: BankAccountInputProps): JSX.Element {
  const form = useForm<InferOutput<typeof depositAccountSchema>>({
    resolver: valibotResolver(depositAccountSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      depositAccount: String(worker.config.paymentAddress),
    },
  });

  // Make the form's value reflect the current state of the worker.
  useEffect(() => {
    if (worker && depositAccount instanceof window.dcp.wallet.Address) {
      form.setValue('depositAccount', worker.config.paymentAddress);
    }
  }, [form, worker, depositAccount]);

  const onOpenChange = () => {
    if (show) {
      form.reset();
      setShow(false);
    }
  };

  return (
    <Dialog open={show} onOpenChange={onOpenChange}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className="text-left">Change Bank Account</DialogTitle>
        </DialogHeader>
        <hr className="border-white" />
        <Form {...form}>
          <form id="depositAccount" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="depositAccount"
              render={({ field }) => (
                <FormItem className="flex flex-col items-center justify-between xl:items-start xl:justify-start">
                  <FormLabel className="dcp-text-col w-[100%] font-bold text-base">
                    Deposit Account
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={defaultDepositAccount.toString()}
                      spellCheck="false"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogFooter>
          <Button type="submit" form="depositAccount">
            Change
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
