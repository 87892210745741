/**
 * @file    RunDCP.tsx - A card that deals with worker status and management
 * @author  David Courtis <david@distributive.network>
 *          Kevin Yu <bryan@distributive.network>
 * @date    Nov. 2023
 */

import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Title } from '@/components/ui/title';
import { SandboxAnimation, WorkAnimation } from '@/features/worker';
import { debugLog } from '@/lib/debug';
import { cn } from '@/lib/utils';

interface RunDCPProps {
  worker: any;
  workerState: any;
}

/**
 * Compute Group Block
 *
 * @param props - component props
 * @param props.worker - the worker object
 * @param props.workerState - The current state the worker is in
 * @returns the rendered component
 */
function RunDCP({ worker, workerState }: RunDCPProps): JSX.Element {
  const [workerStatusText, setWorkerStatusText] = useState(
    'Run DCP jobs to earn Compute Credits',
  );

  function toggleWorker() {
    if (workerState.working) {
      worker.stop(true).catch((error: Error) => {
        console.error('Stopping the worker threw an unexpected error:', error);
      });
    } else {
      worker
        .start()
        .catch((error: Error) =>
          console.error(
            'Starting the worker threw an unexpected error:',
            error,
          ),
        );
    }
  }

  useEffect(() => {
    debugLog('Sandboxes:', worker.sandboxes);
  }, [worker.sandboxes]);

  useEffect(() => {
    const onFetch = (event: any) => {
      debugLog('Worker fetched:', event);
      if (typeof event.jobs !== 'object') {
        throw event;
      }

      const fetchedJobCount = Object.keys(event.jobs).length;

      // Is the worker fetching work while idling?
      if (fetchedJobCount === 0 && worker.workingSandboxes.length === 0) {
        setWorkerStatusText('Waiting for Work');
      } else if (fetchedJobCount !== 0) {
        setWorkerStatusText('WORKING');
      }
    };

    worker.on('fetch', onFetch);

    return () => {
      worker.off('fetch', onFetch);
    };
  }, [worker]);

  useEffect(() => {
    if (!worker.working) {
      setWorkerStatusText('Run DCP jobs to earn Compute Credits');
    } else if (worker.sandboxes.length === 0) {
      setWorkerStatusText('FETCHING');
    } else {
      setWorkerStatusText('WORKING');
    }
  }, [worker, worker.working, worker.sandboxes.length]);

  return (
    <>
      <Card className="relative flex min-h-[320px] flex-col items-center justify-start gap-[30px] pt-[15px] pb-[10px] transition-all xl:min-h-[660px] xl:gap-[60px] xl:pt-[30px] xl:pb-[30px]">
        {/* TITLE */}
        <div className="z-[1] flex items-center justify-center">
          {/* Fetching/Working */}

          <div className="relative flex h-[auto] items-center">
            <Title className="!leading-none mb-0 self-end text-center font-bold text-base xl:text-xl">
              {workerStatusText}
            </Title>
            {worker.working && (
              <div className="ellipses absolute right-[0] translate-x-[110%] text-[30px] " />
            )}
          </div>
        </div>

        {/* DCP LOGO */}
        <div className="flex w-[100%] flex-col items-center justify-center gap-[30px] xl:gap-[60px] xl:pb-[0px]">
          <WorkAnimation isRunning={worker.working} />

          {/* Start and Stop */}
          <Button
            className={cn(
              'z-[1] transition-all duration-500 ease-in-out',
              { 'bg-[#1AA473] hover:bg-[#1AA473]/90': !worker.working },
              {
                'translate-y-[-40px] scale-[80%] bg-secondary/40 hover:bg-secondary/70 xl:translate-y-[-65px]':
                  worker.working,
              },
            )}
            onClick={(_event) => {
              toggleWorker();
            }}
          >
            {worker.working ? 'STOP' : 'START'}
          </Button>
        </div>

        {/* Workspaces */}
        {worker.working && (
          <div
            className={cn(
              'lg:grid-row-auto mt-[-63px] grid max-h-[400px] w-[100%] grid-flow-col grid-cols-desktopSandboxes items-start justify-center gap-[12px] overflow-auto pb-[10px] md:grid-flow-dense md:gap-[25px] lg:w-[65vw] lg:auto-cols-auto xl:w-[90%] xl:gap-[30px] xl:overflow-hidden',
              { 'min-h-[113px] lg:min-h-auto': worker.working },
              {
                'justify-start md:justify-center': worker.sandboxes.length > 2,
              },
            )}
          >
            {worker.sandboxes.map((sandbox: any) => (
              <SandboxAnimation key={sandbox.id} sandbox={sandbox} />
            ))}
          </div>
        )}
      </Card>
    </>
  );
}

export { RunDCP };
