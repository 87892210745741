/**
 * @file    JoinComputeGroup.tsx - A component that deals with the logic for
 *          joining a compute group.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

import type Worker from 'dcp/worker';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import { Title } from '@/components/ui/title';
import { BASE_PATH, DEFAULT_JOIN_KEY } from '@/config';
import {
  type ComputeGroupJoinDetails,
  JoinCredentialDialog,
} from '@/features/compute-group';
import { debugLog } from '@/lib/debug';

interface ComputeGroupProps {
  worker: Worker;
}

/**
 * Renders a form and dialog component for joining a compute group.
 *
 * @param props - Component properties.
 * @param props.worker - An instance of a DCP Worker that will join the compute group.
 * @returns The rendered component.
 */
function JoinComputeGroup({ worker }: ComputeGroupProps): JSX.Element {
  const [isJoinCredentialDialogVisible, setIsJoinCredentialDialogVisible] =
    useState(false);
  const [currentJoinKey, setCurrentJoinKey] = useState(DEFAULT_JOIN_KEY);

  /**
   * Tries to detect if a join key has been specified from path name or query
   * string. e.g., `/demo` or `/?joinKey=demo`.
   */
  useEffect(() => {
    /**
     * Workaround for different pathnames in development vs. staging vs.
     * production. e.g., GitLab Pages or review apps using previewable artifacts
     * from jobs as the `BASE_PATH`.
     *
     * Ignore `index.html` as a potential compute group name to better support
     * previewing through previewable job artifacts on GitLab. If someone names
     * a compute group 'index.html', then they should use something other than
     * dcp.work!
     */
    const joinKeyRegex = new RegExp(
      `${BASE_PATH}/(?:(?!index.html)(?<newJoinKey>.*)?)?`,
    );
    let newJoinKey: string;

    debugLog('Default join key:', DEFAULT_JOIN_KEY);
    debugLog('Join Key Regex:', joinKeyRegex);

    newJoinKey =
      window.location.pathname.match(joinKeyRegex)?.groups?.newJoinKey ??
      DEFAULT_JOIN_KEY;

    debugLog('Join key after inferring from pathname:', newJoinKey);

    // Try query string to support testing on previewable GitLab CI artifacts.
    if (newJoinKey === DEFAULT_JOIN_KEY) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      newJoinKey = urlSearchParams.get('joinKey') ?? DEFAULT_JOIN_KEY;
      debugLog('Join key inferred from search parameter:', {
        newJoinKey,
      });
    }

    const isJoiningComputeGroup = newJoinKey !== DEFAULT_JOIN_KEY;
    setCurrentJoinKey(newJoinKey);
    setIsJoinCredentialDialogVisible(isJoiningComputeGroup);
  }, []);

  useEffect(() => {
    if (!(currentJoinKey !== DEFAULT_JOIN_KEY) && worker) {
      worker.config.computeGroups = [];
      worker.config.leavePublicGroup = false;
    }
  }, [worker, currentJoinKey]);

  const setComputeGroupToJoin: SubmitHandler<{
    joinKey: string;
    joinCredential: string;
  }> = ({ joinKey, joinCredential }) => {
    let computeGroupToJoin: ComputeGroupJoinDetails;

    if (!joinKey) {
      joinKey = DEFAULT_JOIN_KEY;
    }

    if (joinCredential.startsWith('eh1-')) {
      computeGroupToJoin = {
        joinKey,
        joinHash: joinCredential,
      };
    } else {
      computeGroupToJoin = {
        joinKey,
        joinSecret: joinCredential,
      };
    }

    debugLog({ computeGroupToJoin });

    worker.config.computeGroups = [computeGroupToJoin];
    worker.config.leavePublicGroup = true;

    setCurrentJoinKey(joinKey);
    setIsJoinCredentialDialogVisible(false);
  };

  return (
    <div className="flex flex-row items-center justify-between xl:flex-col xl:items-start xl:justify-start">
      <Title className="dcp-text-col mb-[0] w-[100%] font-bold text-base xl:mb-[10px] xl:text-xl">
        Compute Group
      </Title>
      <div className="relative mt-2 hidden w-[100%] xl:block">
        <button
          type="button"
          className="inline-flex w-[100%] overflow-hidden text-ellipsis whitespace-nowrap bg-[#ffffff33] px-[20px] py-[8px] font-bold font-source-code text-[18px] text-lg text-white ring-offset-background file:border-0 file:bg-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {
            setIsJoinCredentialDialogVisible(true);
          }}
        >
          <span className="overflow-hidden text-ellipsis">
            {currentJoinKey.toString()}
          </span>
        </button>
      </div>
      <div className="flex w-[100%] items-center justify-end gap-[7px]">
        <label className="block w-[8rem] overflow-hidden text-ellipsis whitespace-nowrap text-right font-source-code text-[18px] xl:hidden xl:w-[100%]">
          {currentJoinKey.toString()}
        </label>
        <button
          type="button"
          className="block font-[600] text-[#6CCA98] text-[24px] xl:hidden"
          onClick={() => {
            setIsJoinCredentialDialogVisible(true);
          }}
        >
          +
        </button>
      </div>
      <JoinCredentialDialog
        show={isJoinCredentialDialogVisible}
        setShow={setIsJoinCredentialDialogVisible}
        onSubmit={setComputeGroupToJoin}
      />
    </div>
  );
}

export { JoinComputeGroup };
