/**
 * @file    SlicesComputed.tsx - Exports a card component that displays the
 *          count of slices completed.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @author  David Courtis <david@distributive.network>
 * @date    Nov. 2023
 */

import { Card } from '@/components/ui/card';
import { Title } from '@/components/ui/title';

type WorkerStatsProps = {
  totalSlicesDone: number;
};

/**
 * Renders a card component that displays the total number of slices completed.
 *
 * @param props - Component properties.
 * @param props.totalSlicesDone - The count of slices completed.
 * @returns The rendered component.
 */
function SlicesComputed({ totalSlicesDone }: WorkerStatsProps) {
  return (
    <>
      <Card className="flex items-center justify-between gap-[20px] px-[30px] py-[0px] xl:flex-col xl:p-[30px]">
        <Title className="dcp-text-col mb-[0] w-[100%] font-bold text-base xl:mb-[10px] xl:text-xl">
          Slices Completed
        </Title>
        {/* TODO: fix absolute to relative. */}
        <div className="flex w-[100%] flex-wrap items-center justify-end gap-[6px] font-[400] font-source-code text-[20px] xl:absolute xl:top-0 xl:right-0 xl:bottom-0 xl:left-[-30px] xl:flex xl:items-center">
          <p>{totalSlicesDone.toLocaleString()}</p>
        </div>
      </Card>
    </>
  );
}

export { SlicesComputed };
