/**
 * @file    input.tsx - Displays a form input field or a component that looks
 *          like an input field.
 *
 *          Generated from `shadcn/ui`: https://ui.shadcn.com/
 *
 * @author  Bryan Hoang <bryan@distributve.network>
 * @date    Nov. 2023
 */

import { type InputHTMLAttributes, forwardRef } from 'react';

import { cn } from '@/lib/utils';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        spellCheck={false}
        className={cn(
          'flex h-10 w-[100%] w-full border border-border border-solid bg-[#ffffff33] px-[20px] py-[10px] font-bold text-lg text-white ring-offset-background file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };
