/**
 * @file    Dashboard.tsx - Exports a function that renders the application as a
 *          Single Page Application (SPA).
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @author  David Courtis <david@distributive.network>
 * @date    Nov. 2023
 */

import Image from 'next/image';
import { useEffect } from 'react';
import { useDCPWorker } from 'use-dcp-worker';

import dcpLoadingImage from '@/assets/img/dcp-loading.gif';
import { Card } from '@/components/ui/card';
import { DEFAULT_BANK_ACCOUNT } from '@/config';
import { DepositAccountForm, useAddress } from '@/features/account';
import { JoinComputeGroup } from '@/features/compute-group';

import { CPUCoresForm } from '../components/CPUCoresForm';
import { CreditsEarned } from '../components/CreditsEarned';
import { RunDCP } from '../components/RunDCP';
import { SlicesComputed } from '../components/SlicesComputed';
import { TotalComputingTime } from '../components/TotalComputingTime';

/**
 * Renders an SPA.
 *
 * @returns The rendered SPA.
 */
export function Dashboard(): JSX.Element {
  const [depositAccount, setDepositAccount] = useAddress();
  const { worker, workerState, workerStatistics } = useDCPWorker({
    workerOptions: {
      paymentAddress: DEFAULT_BANK_ACCOUNT,
      hardware: { vCores: 1 },
    },
  });

  // Update the payment address for the worker if it's specified correctly.
  useEffect(() => {
    if (
      worker &&
      !(depositAccount instanceof Error) &&
      !worker.config.paymentAddress.eq(depositAccount)
    ) {
      worker.config.paymentAddress = depositAccount;
    }
  }, [worker, depositAccount]);

  // Make the instance of the worker globally accessible for debugging.
  useEffect(() => {
    window.dcpWorker = worker;
  }, [worker]);

  return !worker ? (
    <div className="loaderContainer">
      <Image
        className="dcp-loading"
        src={dcpLoadingImage}
        width={30}
        height={30}
        alt="dcp spinner"
      />
    </div>
  ) : (
    <div className="flex grid-cols-4 grid-rows-1 flex-col gap-[10px] p-[10px] xl:grid xl:gap-[15px] xl:p-[15px]">
      <div className="dcp-gradient order-[3] col-span-1 flex flex-col gap-[0] xl:order-none xl:gap-[15px] xl:bg-none">
        <Card className="overflow-visible py-[10px] pb-0 xl:py-[30px] xl:pb-[30px]">
          <JoinComputeGroup worker={worker} />
        </Card>
        <Card className="overflow-visible py-0 pt-0 pb-0 xl:py-[30px] ">
          <DepositAccountForm
            worker={worker}
            defaultDepositAccount={DEFAULT_BANK_ACCOUNT}
            depositAccount={depositAccount}
            setDepositAccount={setDepositAccount}
          />
        </Card>
        <Card className="overflow-visible py-[15px] pt-0 pb-[10px] xl:py-[30px] ">
          <CPUCoresForm worker={worker} />
        </Card>
      </div>

      {/* RUN DCP BUTTON */}
      <div className="order-first col-span-2 xl:order-none">
        <RunDCP worker={worker} workerState={workerState} />
      </div>

      <div
        className={
          'dcp-gradient relative order-[2] flex flex-col gap-0 overflow-hidden xl:order-none xl:gap-[15px] xl:bg-none'
        }
      >
        <CreditsEarned totalCreditsEarned={workerStatistics.credits} />
        <SlicesComputed totalSlicesDone={workerStatistics.slices} />
        <TotalComputingTime
          totalComputingTime={workerStatistics.computeTime * 1000}
        />
      </div>
    </div>
  );
}
